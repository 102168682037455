@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@600;700;800;900&family=Outfit:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Literata:wght@600;700;800;900&family=Nunito:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Poppins:wght@600;700;800;900&display=swap');

@import '_vars';
@import '_base';
@import 'components/_import';

%gradient_title {
    color: #FFBA41;
    background: linear-gradient(92.85deg, #FFBA41 47.63%, #FFE0AC 96.76%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: var(--font_title);
}

.page404 {
	padding: 70px 0;
	text-align: center;
	min-height: 45vh;
}
