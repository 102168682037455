.Intro {
	padding: 120px 0;
	background-position: bottom center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;

	@media(max-width: 1600px) {
		padding: 90px 0;
	}

	@media(max-width: 1200px) {
		padding-bottom: 30px;
	}

	@media(max-width: 991px) {
		padding-top: 60px;
	}

	@media(max-width: 767px) {
		padding-bottom: 0;
		padding-top: 30px;
	}

	&-bg {
		position: absolute;
		top: -50px;
		right: 5%;
		z-index: 1;
		display: block;

		@media(max-width: 767px) {
			left: 0;
			top: -10px;
		}
	}

	&_wrap {
		display: flex;
		align-items: center;
		justify-content: space-between;

		@media(max-width: 767px) {
			flex-direction: column-reverse;
		}
	}

	&-image {
		min-width: 40%;
		flex: 0 0 40%;

		img {
			display: block;
		}
	}

	&-content {
		min-width: 60%;
		flex: 0 0 60%;
		font-size: 24px;
		padding-left: 50px;
		padding-bottom: 60px;

		@media(max-width: 991px) {
			padding-bottom: 0;
			padding-left: 20px;
		}

		@media(max-width: 767px) {
			padding-left: 0;
			margin-bottom: 15px;
		}

		&_btn {
			margin-top: 70px;

			@media(max-width: 991px) {
				margin-top: 40px;
			}
		}

		@media(max-width: 1400px) {
			font-size: 18px;
		}
	}

	&-title {
		font-weight: 900;
		font-size: 44px;
		line-height: 1.2;
		letter-spacing: 0.035em;
		color: #23D000;
		font-family: var(--font_title);
		margin: 0 0 30px;

		@media(max-width: 1400px) {
			font-size: 36px;
		}

		@media(max-width: 991px) {
			font-size: 28px;
		}

		span {
			display: block;
			font-size: 80px;
			letter-spacing: normal;
			color: var(--colorPink);

			@media(max-width: 1400px) {
				font-size: 52px;
			}

			@media(max-width: 991px) {
				font-size: 41px;
			}
		}
	}
}
