.AccountPage {
	padding: 50px 0;

	@media (max-width: 767px) {
		padding: 0 0 80px;
	}
}

.SelectContract {
	padding: 75px 0;

	@media (max-width: 1024px) {
		padding: 50px 0;
	}

	&-account {
		text-align: center;
		margin-bottom: 50px;
		font-size: 24px;

		@media (max-width: 1024px) {
			font-size: 18px;
			margin-bottom: 30px;
		}

		@media (max-width: 640px) {
			font-size: 14px;
		}

		strong {
			font-size: 36px;
			display: block;

			@media (max-width: 1024px) {
				font-size: 24px;
			}

			@media (max-width: 767px) {
				font-size: 21px;
			}

			@media (max-width: 640px) {
				font-size: 18px;
				word-wrap: break-word;
			}
		}
	}

	&_wrap {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 40px;
		max-width: 960px;
		margin: 0 auto;

		@media (max-width: 1024px) {
			grid-gap: 30px;
		}

		@media (max-width: 640px) {
			grid-template-columns: repeat(1, 1fr);
		}
	}

	&Item {
		padding: 60px 40px 40px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-radius: 10px;
		background: var(--colorMatic);

		@media (max-width: 1024px) {
			padding: 40px 30px 30px;
		}

		@media (max-width: 767px) {
			padding: 20px 15px 15px;
		}

		&-image {
			margin-left: 30px;

			img {
				display: block;

				@media (max-width: 1024px) {
					max-width: 90px;
				}

				@media (max-width: 767px) {
					max-width: 70px;
				}
			}
		}

		&-info {
		}

		&-title {
			font-weight: 700;
			font-size: 24px;
			color: #fff;
			margin-bottom: 50px;

			@media (max-width: 1024px) {
				margin-bottom: 30px;
				font-size: 18px;
			}
		}

		&-link {
			text-align: center;
			display: inline-block;
			max-width: 220px;
			width: 100%;
			padding: 20px 45px;
			background: rgba(255, 255, 255, 0.5);
			border-radius: 60px;
			font-size: 16px;
			font-weight: 700;
			color: #fff;

			&:hover {
				background-color: #fff;
				color: var(--colorMatic);
			}

			&:disabled {
				background-color: #a0a0a0;
				color: var(--colorMatic);
			}

			@media (max-width: 1024px) {
				padding: 15px 25px;
			}
		}
	}
}
