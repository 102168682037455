.FColStats {
	background: var(--colorGrey);
	border: 3px solid var(--colorMatic);
	padding: 50px;
	margin-bottom: 30px;

	@media (max-width: 991px) {
		padding: 15px;
	}

	&_table {
		overflow: auto;

		@media (max-width: 767px) {
			padding-bottom: 15px;
		}

		table {
			width: 100%;
			border-collapse: collapse;
			min-width: 700px;

			th {
				padding: 0 5px 30px;
				border-bottom: 2px solid var(--colorMatic);
				text-align: center;
				font-weight: 500;

				@media (max-width: 991px) {
					padding: 0 5px 15px;
				}

				&:first-child {
					text-align: left;
					font-size: 18px;
					font-weight: 700;
					padding-left: 0;

					@media (max-width: 991px) {
						font-size: 16px;
					}
				}

				&:last-child {
					padding-right: 0;
				}
			}

			td {
				padding: 15px 5px;
				border-bottom: 0.5px solid #f2f2f2;
				text-align: center;
				font-weight: 500;

				@media (max-width: 991px) {
					padding: 10px 5px;
					font-size: 14px;
				}

				&:first-child {
					text-align: left;
					padding-left: 0;
				}

				&:last-child {
					padding-right: 0;
				}
			}

			tbody {
				tr {
					&:first-child {
						td {
							padding-top: 30px;

							@media (max-width: 991px) {
								padding-top: 15px;
							}
						}
					}

					&:last-child {
						td {
							border-bottom: 0;
							padding-bottom: 0;
						}
					}
				}
			}
		}
	}
	&_head {
		display: flex;
		align-items: center;
		padding: 8px;
		width: 100%;
		border-bottom: 2px solid var(--colorMatic);
	}

	&_claim {
		display: flex;
		align-items: center;
		border-bottom: 2px solid #ffffff;

		@media (max-width: 767px) {
			padding-bottom: 15px;
		}

		&_label {
			width: 30%;
			text-align: center;
			padding-right: 10px;
			font-size: 20px;
			font-weight: 500;
		}

		&_value {
			width: 30%;
			text-align: center;
			font-size: 16px;
			padding: 0px 16px;

			input {
				height: 40px;
				width: 100%;
				padding: 5px 50px 5px 15px;
				font-size: 16px;
				background: #fff;
				box-shadow: 0px 0px 10px rgba(75, 119, 224, 0.2);
				border-radius: 4px;
				border: 1px solid rgba(75, 119, 224, 0.2);
			}
		}

		&_button {
			width: 30%;
			text-align: center;
			padding: 8px;
		}

		button {
			&:disabled {
				background-color: #505050;
				color: var(--colorGrey2);
			}
		}

		&_content {

			&_danger {
				color: #FC49EF;
				font-weight: 600;
			}
		}
	}
}
