.Footer {
	background: var(--colorGrey);
	padding: 10px 0;
	border: 3px solid transparent;
	border-image: linear-gradient(354deg, rgb(71 203 138 / 46%), var(--colorGrey), rgb(71 203 138 / 46%)) 1;
	position: absolute;
	bottom: 0;
	width: 100%;
	z-index: 100;

	&-title {
		text-align: center;
		text-transform: uppercase;
		margin: 0 0 50px;
		color: var(--colorGreen);
		font-weight: 800;
		font-size: 32px;

		@media(max-width: 1400px) {
			font-size: 24px;
			margin-bottom: 30px;
		}

		@media(max-width: 991px) {
			font-size: 18px;
			margin-bottom: 15px;
		}
	}

	.socials {
		margin-bottom: 80px;
		display: flex;
		align-items: center;
		justify-content: center;

		@media(max-width: 1400px) {
			margin-bottom: 50px;
		}

		@media(max-width: 991px) {
			margin-bottom: 30px;
		}

		a {
			display: block;
			margin: 0 24px;

			@media(max-width: 1400px) {
				margin: 0 15px;
			}

			@media(max-width: 767px) {
				margin: 0 7px;
			}

			svg {
				display: block;
				width: 80px;
				height: 80px;
				fill: var(--colorGreen);
				transition: 0.3s;

				@media(max-width: 1400px) {
					width: 64px;
					height: 64px;
				}

				@media(max-width: 991px) {
					width: 48px;
					height: 48px;
				}

				@media(max-width: 767px) {
					width: 24px;
					height: 24px;
				}
			}

			&:hover {
				svg {
					fill: #fff;
				}
			}
		}
	}

	&_bottom {
		padding: 15px 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-weight: 500;
		font-size: 16px;
		letter-spacing: 0.02em;
		color: var(--colorGreen);

		@media(max-width: 767px) {
			font-size: 12px;
		}
	}

	.lang-switcher {
		button {
			color: #fff;

			&:hover {
				color: var(--colorGreen);
			}
		}
	}
}
