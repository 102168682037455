.FColWithdrawModal {
	border-color: rgba(130, 71, 229, 0.41);
	background: rgba(130, 71, 229, 0.41);
	max-width: 500px;
	margin: auto;

	.modal_close {
		svg {
			color: var(--colorMatic);
		}

		&:hover {
			svg {
				color: #fff;
			}
		}
	}

	&_title {
		color: white;
		font-size: 20px;
		font-weight: 700;
		text-align: center;
		margin: 32px 0px;
	}

	&_text {
		color: white;
		font-size: 16px;
		font-weight: 500;
		margin-bottom: 16px;
	}

	&_footer {
		display: flex;
		justify-content: center;

		button {
			text-align: center;
			margin: 16px;
			padding: 8px;

			&:disabled {
				background-color: #505050;
				color: var(--colorGrey2);
			}
		}
	}

	&_button_error {
		background: #d500a6;
		color: #ffffff;
		width: 140px;
	}

	&_button_success {
		background: #00b85e;
		color: #ffffff;
		width: 140px;
	}
}
