*, *::before, *::after {
    box-sizing: border-box;
}

html {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    height: 100%;
    touch-action: manipulation;
}

body {
    margin: 0;
    padding: 0;
	min-height: 100%;
	width: 100%;
    font-family: var(--font_main);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #fff;
    line-height: 1.5;
    background: var(--colorBlack);
	position: relative;
}

#root {
	background: var(--colorBlack);
}

img {
    max-width: 100%;
    outline: 0;
}
