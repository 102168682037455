.FColNftMint {
	@media (max-width: 991px) {
		padding: 15px;
	}

	&-form {
		background: var(--colorGrey);
		border: 2px solid var(--colorMatic);
		padding: 30px;
		margin-right: 10px;
		overflow: auto;
		width: 70%;

		@media (max-width: 767px) {
			padding-bottom: 15px;
		}

		&-row {
			padding: 4px;
			display: flex;
		}

		&-cell {
			height: 48px;
			font-size: 15px;
			font-weight: 400;
			background: var(--colorGrey2);
			box-sizing: border-box;
			border: 1px solid #c4c4c4;
			color: #fff;
			width: 50%;
			display: flex;

			span {
				margin: auto;
			}

			&-value {
				color: var(--colorMatic);
				font-weight: 700;
				width: 100%;
				display: flex;
			}
		}

		&_select {
			width: 100%;
			height: 48px;
			margin-bottom: 15px;
			padding: 5px 15px;
			font-size: 16px;
			background: var(--colorGrey2);
			border-color: #c4c4c4;
			color: #fff;
		}

		&-btn {
			text-align: center;
			width: 100%;
			height: 48px;
			margin: 15px 0;

			button {
				width: 100%;
				background: #00b85e;
				box-shadow: 0 0 15px rgba(1, 54, 180, 0.15);
				color: #000000;
				font-weight: 600;
				font-size: 14px;
				&:disabled {
					background-color: #505050;
					color: var(--colorGrey2);
				}
			}
		}
	}
	&-avatar {
		background: var(--colorGrey);
		box-sizing: border-box;
		border: 2px solid var(--colorMatic);
		padding: 10px;
		width: 30%;
	}
}
