.ModalNode {
	border: 2px solid #007d40;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	max-width: 600px;
	background: #141b22;
	border-radius: 15px;
	margin: auto;

	.modal_close {
		svg {
			color: #ffffff;
		}

		&:hover {
			svg {
				color: #fff;
			}
		}
	}

	.modal_title {
		color: #ffffff;
		font-weight: 400;
	}

	&-form {
		&_body {
			height: 400px;
			overflow-y: scroll;
			&::-webkit-scrollbar {
				background: #007d40;
				width: 2px;
			}
			&::-webkit-scrollbar-track {
				background: #313a85;
			}
			&::-webkit-scrollbar-thumb {
				background: #007d40;
				width: 2px;
			}
		}
		&_row {
			display: flex;
			background: #141b22;
		}

		&_header {
			padding: 12px 6px;
			font-size: 14px;
			margin: 1px;
			color: #8247e5;
			text-align: center;
		}

		&_cell {
			padding: 8px 6px;
			font-size: 12px;
			margin: 1px;
			text-align: center;
		}

		&_btn {
			text-align: center;
			width: 100%;
			padding: 10px 2px;
			color: white;

			&_svg {
				width: 20%;
				text-align: right;
				padding-right: 10px;
			}

			&_text {
				width: 80%;
				text-align: left;
			}

			button {
				width: 100%;
				background-color: #41ca88;
				display: flex;
				justify-content: center;
				align-items: center;

				img {
					width: 28px;
					height: 28px;
				}

				span {
					padding-left: 4px;
				}

				&:hover {
					background-color: #0be97e;
					color: var(--colorGrey2);
				}
				&:disabled {
					background-color: #6a977b;
					color: var(--colorGrey2);
				}
			}
		}
	}
}
