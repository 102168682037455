.FColLineStructure {
	margin-top: -20px;
	margin-bottom: 10px;
	padding: 3px;

	&-title {
		font-size: 18px;
		color: var(--colorGreen);
		font-weight: 700;
		margin-bottom: 30px;
	}
}
