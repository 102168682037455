.FColNftMyMint {
	@media (max-width: 991px) {
		padding: 15px;
	}

	&-row {
		padding: 4px;
		display: flex;
		flex-wrap: wrap;

		@media (max-width: 640px) {
			flex-direction: column;
		}
	}

	&-cell {
		flex-basis: 20%;
	}

	@media (max-width: 1500px) {
		&-cell {
			flex-basis: 25%;
		}
	}

	&-card {
		background: var(--colorGrey);
		box-sizing: border-box;
		margin: 5px;

		&-active {
			opacity: 1;
			border: 2px solid #21e786;
			padding: 6px;
		}

		&-inactive {
			opacity: 0.5;
			border: 2px solid transparent;
			padding: 6px;
		}
	}

	&-btn {
		text-align: center;
		width: 100%;
		margin: 10px 0;

		button {
			width: 100%;
			height: 46px;
			box-shadow: 0 0 15px rgba(1, 54, 180, 0.15);
			font-weight: 600;
			font-size: 14px;

			&:disabled {
				background-color: #505050;
				color: var(--colorGrey2);
			}
		}
	}
	&-series {
		display: 'flex';
		text-transform: uppercase;
		align-items: center;
		text-align: center;
		width: 100%;
		font-size: 14px;
		font-weight: 600;
		background: #8247e5;
		padding: 6px;
		color: #ffffff;
	}

	&-label {
		text-align: center;
		width: 100%;

		&--transparent {
			width: 100%;
			box-sizing: border-box;
			border: 2px solid #821592;
			background: transparent;
			color: #ffffff;
			padding: 6px;
			font-style: normal;
			font-weight: 600;
			font-size: 14px;
			line-height: 17px;

			&:disabled {
				border: 2px solid #505050;
				background-color: #505050;
				color: var(--colorGrey2);
			}
		}

		&--pink {
			width: 100%;
			background: linear-gradient(180deg, #821592 0%, #d500a6 100%);
			box-shadow: 0 0 15px rgba(1, 54, 180, 0.15);
			font-style: normal;
			font-weight: 600;
			font-size: 14px;
			line-height: 17px;
			text-align: center;
			color: #ffffff;
			display: inline-block;
			text-transform: uppercase;
			padding: 13px 10px;
			transition: 0.3s;
			height: 39px;

			&:hover {
				background: linear-gradient(180deg, #d500a6 0%, #e20cb4 100%);
			}

			&:disabled {
				background: linear-gradient(180deg, #505050 0%, #505050 100%);
				color: var(--colorGrey2);
			}
		}
	}
}
.btn--matic {
	background: var(--colorMatic);
	color: #ffffff;
}
.btn--green {
	background: #00b85e;
	color: #000000;
}
.btn--transparent {
	box-sizing: border-box;
	border: 1px solid #ffffff;
	background: transparent;
	color: #ffffff;
}
