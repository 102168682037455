.Sidebar {
	min-width: 360px;
	flex: 0 0 25%;
	border-radius: 2px;
	background: var(--colorGrey);
	margin-bottom: 30px;
	border-bottom-right-radius: 10px;
	border-bottom-left-radius: 10px;

	@media (max-width: 1200px) {
		position: absolute;
		width: 320px;
		min-width: 0;
		left: -320px;
		top: 108px;
		bottom: 0;
		transition: 0.3s;
		margin-bottom: 0;
		overflow-y: auto;
		border-right: 1px solid var(--colorMatic);
		border-top: 1px solid var(--colorMatic);

		&--active {
			left: 0;
		}
	}

	@media (max-width: 767px) {
		top: 77px;
	}

	&Info {
		padding: 30px;
		background: var(--colorGreyL);

		@media (max-width: 1600px) {
			padding: 15px;
		}

		&-row {
			margin-bottom: 15px;

			@media (max-width: 1600px) {
				margin-bottom: 10px;
			}

			b {
				margin-right: 15px;

				@media (max-width: 1600px) {
					margin-right: 5px;
				}
			}

			span {
				color: var(--colorMatic);
			}

			.btn {
				margin-left: 15px;

				@media (max-width: 1600px) {
					margin-left: 10px;
				}
			}
		}

		&-refLink {
			strong {
				display: block;
				margin-bottom: 5px;
			}

			&_input {
				position: relative;

				input {
					height: 40px;
					width: 100%;
					padding: 5px 50px 5px 15px;
					font-size: 16px;
					background: #fff;
					box-shadow: 0px 0px 10px rgba(75, 119, 224, 0.2);
					border-radius: 4px;
					border: 1px solid rgba(75, 119, 224, 0.2);
				}

				button {
					display: flex;
					align-items: center;
					justify-content: center;
					border: 0;
					background: var(--colorMatic);
					border-top-right-radius: 4px;
					border-bottom-right-radius: 4px;
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					height: 40px;
					width: 40px;

					svg {
						display: block;
						fill: #fff;
						width: 16px;
						height: 16px;
					}
				}
			}
		}
	}

	&-nav {
		@media (max-width: 1600px) {
			padding: 10px 5px;
		}

		a {
			display: flex;
			padding: 20px 30px;
			align-items: center;
			font-size: 18px;
			color: #fff;

			svg {
				min-width: 24px;
				flex: 0 0 24px;
				height: 24px;
				display: block;
				margin-right: 30px;
			}

			&:hover,
			&.active {
				color: var(--colorGreen);
			}
		}
	}

	&-submenu {
		cursor: pointer;
		div {
			display: flex;
			align-items: center;
			font-size: 18px;
			color: #fff;

			svg {
				min-width: 24px;
				margin: 10px 30px;
				flex: 0 0 24px;
				height: 24px;
				display: block;
			}

			&:hover,
			&.active {
				color: var(--colorGreen);
			}
		}

		&-dropdown {
			padding-left: 8px;
		}
	}

	&-subitem {
		background: #0a0d11;
		width: 100%;

		a {
			display: flex;
			padding: 10px 80px;
			align-items: center;
			font-size: 18px;
			font-weight: 400;
			color: #414d55;
			box-sizing: border-box;
			border-bottom: 1px solid #414d55;

			&:hover,
			&.active {
				color: #fff;
			}
		}
	}
	.show {
		display: inline;
	}
	.hide {
		display: none;
	}
}
