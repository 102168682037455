.Hero {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 85vh;

	&_wrap {
		text-align: center;
	}

	&_content {
		margin-bottom: 5rem;

		@media(max-width: 767px) {
			margin-bottom: 7rem
		}
	}

	&-title {
		font-family: var(--font_title);
		font-size: 100px;
		font-weight: 700;
		line-height: 56px;
		letter-spacing: 0em;
		color: #21E786;

		@media(max-width: 1400px) {
			font-size: 80px;
		}

		@media(max-width: 991px) {
			font-size: 60px;
		}

		@media(max-width: 767px) {
			font-size: 40px;
		}
	}

	&-content {
		font-family: var(--font_title);
		font-size: 50px;
		font-weight: 700;
		line-height: 85px;
		letter-spacing: 0em;

		@media(max-width: 1400px) {
			font-size: 40px;
			line-height: 70px;
		}

		@media(max-width: 991px) {
			font-size: 36px;
			line-height: 60px;
		}

		@media(max-width: 767px) {
			font-size: 26px;
			line-height: 40px;
		}
	}
}



