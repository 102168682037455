:root {
	--font_main: 'Poppins', sans-serif;
	--font_title: 'Poppins', sans-serif;

	--colorBlack: #010101;
	--colorGrey: #141B22;
	--colorGrey2: #172028;
	--colorGreyL: #4D5161;
	--colorGreen: #21E786;
	--colorPink: #FC49EF;

	--colorBnb: #d3a100;
	--colorMatic: #8248e5;
}
