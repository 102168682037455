ul, ol {
    margin: 0 0 15px;

	&:last-child {
		margin-bottom: 0;
	}
}

p {
    margin: 0 0 15px;

	&:last-child {
		margin-bottom: 0;
	}
}

a {
    text-decoration: none;
    outline: 0;
    color: var(--colorGreen);
    transition: 0.3s;

    &:hover, &:active {
        color: var(--colorGreen);
    }

    &[disabled] {
		pointer-events: none;
        color: var(--colorGreyL);
    }
}
