.FColModalInviter {
	border-color: var(--colorMatic);
	max-width: 600px;
	margin: auto;

	.modal_close {
		svg {
			color: var(--colorMatic);
		}

		&:hover {
			svg {
				color: #fff;
			}
		}
	}

	.modal_title {
		color: var(--colorMatic);
	}

	&-form {
		&_input {
			width: 100%;
			height: 48px;
			margin-bottom: 15px;
			padding: 5px 15px;
			font-size: 16px;
			background: var(--colorGrey2);
			border-color: var(--colorMatic);
			color: var(--colorMatic);
		}

		&_btn {
			text-align: center;

			button {
				&:disabled {
					background-color: #505050;
					color: var(--colorGrey2);
				}
			}
		}
	}
}
