.FColBinarBonus {
	&-content {
		margin-bottom: 30px;
	}

	&_table {
		background: var(--colorGrey);
		border: 2px solid var(--colorMatic);
		box-shadow: 0px 0px 15px rgba(1, 54, 180, 0.15);
		padding: 0 5px 10px;
		margin-bottom: 15px;

		&-title {
			padding: 30px 0 25px;
			text-align: center;
			font-size: 18px;
			font-weight: 700;

			@media (max-width: 991px) {
				padding: 15px 0 10px;
			}
		}

		&-wrap {
			overflow: auto;
		}

		table {
			width: 100%;
			border-collapse: collapse;
			text-align: center;
			min-width: 700px;

			th {
				padding: 20px 10px;
				font-weight: 500;
				text-transform: uppercase;

				@media (max-width: 991px) {
					padding: 10px 5px;
				}
			}

			td {
				padding: 20px 15px;
				font-weight: 500;
				color: var(--colorMatic);

				@media (max-width: 991px) {
					padding: 10px 5px;
				}
			}
		}
	}
}
