button {
    outline: 0;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    border: 0;
    background: none;
    padding: 0;
	font-family: var(--font_main);

    &:focus, &:active, &:hover {
        outline: 0;
        box-shadow: none;
    }
}

.btn {
	display: inline-block;
	text-align: center;
	background: var(--colorGreen);
	text-transform: uppercase;
	color: var(--colorGrey);
	padding: 13px 25px;
	transition: 0.3s;
    font-size: 16px;
	height: 48px;

	&:hover {
		background: var(--colorGrey);
		color: var(--colorGreen);
	}

	&--dark {
		background: var(--colorGrey);
		color: var(--colorGreen);

		&:hover {
			background: var(--colorGreen);
			color: var(--colorGrey);
		}
	}

	&--pink {
		background: var(--colorPink);

		&:hover {
			background: var(--colorGrey);
			color: var(--colorPink);
		}
	}

	&--matic {
		background: var(--colorMatic);
		color: #fff;

		&:hover {
			background: var(--colorGrey2);
			color: var(--colorMatic);
		}
	}

	&--sm {
		font-size: 14px;
		padding: 7px 15px;
		height: auto;
	}
}
