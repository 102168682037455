.FColBinarStructure {
	margin-top: -90px;

	&_stat {
		text-align: center;
		margin-top: 24px;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		padding: 4px;

		@media (max-width: 640px) {
			flex-direction: column;
		}

		&_container {
			width: 100%;
			text-align: left;
			margin: 8px;
			padding: 16px;
			color: #fff;
			background: #8247e5;
			border-radius: 8px;
		}

		&_center {
			width: 100%;
			color: #fff;
			text-align: left;
			padding: 0px 16px;
			margin: 0px 8px;

			&_container {
				text-align: left;
				margin: 8px;
				padding: 8px;
				color: #fff;
				background: #8247e5;
				border-radius: 8px;
			}

			&_item {
				padding: 8px 24px 8px 24px;
				text-align: center;
			}

			&_time {
				display: flex;
				width: 100%;
				justify-content: center;
			}

			&_value {
				text-align: center;
				padding-top: 16px;
				font-size: 20px;
				font-weight: 600;
				width: 50%;
			}
		}

		&_header {
			font-size: 20px;
			font-weight: 700;
			padding: 16px 24px 16px 24px;
		}

		&_item {
			padding: 8px 24px 8px 24px;
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
		}

		&_label {
			font-size: 16px;
			font-weight: 400;
			width: 60%;
		}

		&_value {
			font-size: 16px;
			font-weight: 600;
			width: 40%;
		}
	}

	@media (max-width: 1600px) {
		margin-top: -50px;
	}

	@media (max-width: 1200px) {
		margin-top: -30px;
	}
}

.FColBinarStructureItem {
	background: var(--colorGrey2);
	margin-bottom: 15px;
	width: 100%;

	&-wallet {
		font-size: 18px;
		text-align: center;
		margin-bottom: 15px;
		font-weight: 700;
		display: flex;
		align-items: center;
	}

	&-btn {
		margin-right: 15px;
		min-width: 32px;
		flex: 0 0 32px;
		height: 32px;
		border: 0;
		display: block;
		background: var(--colorBnb);
		border-radius: 4px;
		position: relative;

		&::before {
			content: '';
			width: 14px;
			height: 2px;
			background: #fff;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			border-radius: 2px;
		}

		&::after {
			content: '';
			width: 2px;
			height: 14px;
			background: #fff;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			border-radius: 2px;
		}
	}

	&_list {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-gap: 15px;

		b {
			display: block;
			color: var(--colorGreen);
			font-size: 14px;
			font-weight: 500;
		}

		span {
			color: var(--colorBnb);
			font-weight: 700;
		}
	}

	&_childs {
		.FColBinarStructureItem {
			background: var(--colorGrey);

			&:first-child {
				margin-top: 15px;
			}
		}
	}

	&:last-child {
		margin-bottom: 0;
	}

	$this: &;

	&--active {
		& > #{$this}_wrap #{$this}-btn {
			&::after {
				transform: translate(-50%, -50%) rotate(90deg);
			}
		}

		& > #{$this}_childs {
			display: block;
		}
	}
}
