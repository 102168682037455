input, select, textarea {
    outline: 0;
    font-family: var(--font_main);
    font-size: 14px;
    font-weight: 400;

    @media (max-width: 767px) {
        font-size: 16px;
    }
}

label {
    cursor: pointer;
}
