.Collection {
	padding: 110px 0;
	position: relative;

	@media(max-width: 1600px) {
		padding: 90px 0;
	}

	@media(max-width: 1200px) {
		padding: 30px 0;
	}

	&-bg {
		position: absolute;
		top: -120px;
		left: 35%;
		z-index: 1;
		display: block;

		@media(max-width: 767px) {
			left: auto;
			right: 0;
			top: -30px;
		}
	}

	&_wrap {
		position: relative;
		z-index: 5;
		display: flex;
		align-items: center;
		justify-content: space-between;

		@media(max-width: 767px) {
			flex-direction: column;
		}
	}

	&-images {
		min-width: 40%;
		display: block;

		img {
			display: block;
		}

		@media(max-width: 991px) {
			min-width: 45%;
			flex-basis: 45%;
			grid-gap: 60px 15px;
		}

		@media(max-width: 767px) {
			grid-gap: 15px;
		}
	}

	&-title {
		margin: 0 0 50px;
		font-size: 44px;
		font-weight: 700;
		color: #23D000;

		@media(max-width: 1400px) {
			font-size: 36px;
		}

		@media(max-width: 991px) {
			font-size: 28px;
			margin-bottom: 30px;
		}

		@media(max-width: 767px) {
			font-size: 24px;
			margin-bottom: 15px;
		}
	}

	&-content {
		min-width: 50%;
		flex: 0 0 50%;
		padding-right: 50px;
		font-size: 24px;

		@media(max-width: 1400px) {
			font-size: 18px;
		}

		@media(max-width: 1200px) {
			min-width: 55%;
			flex-basis: 55%;
		}

		@media(max-width: 991px) {
			font-size: 16px;
			padding-right: 30px;
		}

		@media(max-width: 767px) {
			padding-right: 0;
			margin-bottom: 30px;
		}

		p {
			margin-bottom: 25px;

			@media(max-width: 767px) {
				margin-bottom: 15px;
			}
		}

		b {
			color: var(--colorGreen);
			margin-right: 20px;
		}
	}

	&_btns {
		margin-top: 50px;
		display: flex;

		@media(max-width: 991px) {
			flex-wrap: wrap;
			margin-top: 30px;
		}

		@media(max-width: 767px) {
			margin-bottom: 15px;
		}

		.btn {
			display: block;
			margin-right: 20px;

			@media(max-width: 991px) {
				margin-bottom: 10px;
			}

			@media(max-width: 767px) {
				margin-right: 10px;
			}
		}
	}

	$this: &;

	&--reverse {
		text-align: right;

		#{$this}_wrap {
			@media(max-width: 767px) {
				flex-direction: column-reverse;
			}
		}

		#{$this}-bg {
			top: 0;
			left: auto;
			right: 40%;
		}

		#{$this}-content {
			padding-right: 0;
			padding-left: 50px;

			b {
				margin-right: 0;
				margin-left: 20px;
			}

			@media(max-width: 767px) {
				padding-left: 0;
			}
		}

		#{$this}_btns {
			justify-content: flex-end;

			.btn {
				margin-right: 0;
				margin-left: 20px;
			}
		}
	}
}
