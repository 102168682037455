.FColTitle {
	background: var(--colorMatic);
	color: #fff;
	text-align: center;
	font-size: 24px;
	font-weight: 700;
	margin-bottom: 30px;
	width: 100%;
	padding: 10px 15px;

	@media (max-width: 991px) {
		font-size: 18px;
		line-height: 1.2;
		padding: 15px;
	}
}
