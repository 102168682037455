.FColPassiveBonus {
	margin-bottom: 30px;
	border: 3px solid var(--colorGreen);
	background: var(--colorGrey);

	&-info {
		border-bottom: 3px solid var(--colorGreen);
		padding: 45px;

		&_title {
			font-size: 18px;
			color: var(--colorGreen);
			font-weight: 700;
			margin-bottom: 30px;

			@media(max-width: 767px) {
				margin-bottom: 15px;
			}
		}

		@media(max-width: 991px) {
			padding: 30px;
		}

		@media(max-width: 767px) {
			padding: 15px;
		}
	}

	&-content {
		&_title {
			font-size: 24px;
			font-weight: 700;
			padding: 15px;
			text-align: center;
			color: var(--colorGreen);
			background: var(--colorGrey2);

			@media(max-width: 991px) {
				font-size: 18px;
			}
		}
	}

	&_table {
		overflow: auto;

		table {
			text-align: center;
			font-size: 15px;
			width: 100%;
			border-collapse: collapse;
			min-width: 800px;

			th {
				color: var(--colorMatic);
				font-weight: 700;
				padding: 15px;
				background: var(--colorGrey);
			}

			td {
				background: var(--colorGrey);
				padding: 10px 15px;
			}

			tbody {
				tr:nth-child(2n + 1) {
					td {
						background: var(--colorGrey2);
					}
				}
			}
		}
	}
}
