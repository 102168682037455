.Layout {
	padding: 90px 0;

	@media(max-width: 1600px) {
		padding: 50px 0 90px;
	}

	@media(max-width: 1200px) {
		padding: 30px 0 80px;
	}

	.container {
		display: flex;
		align-items: flex-start;
		min-width: 0;
	}

	&-content {
		flex: 1;
		min-width: 0;
		padding-left: 30px;

		@media(max-width: 1200px) {
			padding-left: 0;
		}
	}
}
