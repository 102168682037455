.lang-switcher {
	button {
		padding: 5px;
		border: 0;
		color: var(--colorGreen);
		text-transform: uppercase;

		&:hover {
			color: #fff;
		}
	}
}
