.overlay-base.overlay-after {
    background: rgba(21, 6, 51, 0.4) !important;
    backdrop-filter: blur(23px);
}

.content-base {
    max-width: 1200px;
    width: 100%;
    display: block !important;
}

.modal {
    background: var(--colorGrey);
    backdrop-filter: blur(64px);
    overflow: hidden;
    border: 3px solid var(--colorGreen);
	margin: auto;

    &_header {
        padding: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: var(--colorGrey2);
    }

    &_close {
        border: 0;
        background: none;
        display: block;
        min-width: 24px;
        flex: 0 0 24px;
        margin-left: 15px;
        margin-right: 0;

        svg {
            display: block;
            width: 20px;
            height: 20px;
			color: var(--colorGreen);
			transition: 0.3s;
        }

		&:hover {
			svg {
				color: #fff;
			}
		}
    }

    &_title {
        font-weight: 700;
        font-size: 24px;
        color: var(--colorGreen);
    }

    &_body {
        padding: 20px 15px;
    }
}
