.Header {
	padding: 20px 0;
	background: var(--colorBlack);

	@media(max-width: 991px) {
		padding: 15px 0;
	}

	&_wrap {
		display: flex;
		align-items: center;
		justify-content: space-between;

		@media(max-width: 767px) {
			flex-wrap: wrap;
		}
	}

	&-logo {
		margin-right: 30px;
		margin-left: 0;

		img {
			display: block;
			height: 17px;

			@media(max-width: 767px) {
				margin: 0 auto;
			}
		}

		@media(max-width: 767px) {
			min-width: 100%;
			margin-right: 0;
			margin-bottom: 30px;
			margin-top: 15px;
		}
	}

	.socials {
		margin: 0 auto;
		display: grid;
		grid-template-columns: repeat(7, 1fr);
		grid-gap: 15px;

		@media(max-width: 991px) {
			grid-template-columns: repeat(4, 1fr);
			grid-gap: 10px 15px;
		}

		@media(max-width: 767px) {
			margin-left: 0;
			grid-gap: 10px;
		}

		a {
			display: block;

			svg {
				display: block;
				width: 28px;
				height: 28px;
				fill: var(--colorGreen);
				transition: 0.3s;

				@media(max-width: 767px) {
					width: 24px;
					height: 24px;
				}
			}

			&:hover {
				svg {
					fill: #fff;
				}
			}
		}
	}

	&-auth {
		display: flex;

		@media(max-width: 640px) {
			flex-direction: column;
		}

		& > * {
			margin-left: 15px;

			@media(max-width: 640px) {
				margin-left: 5px;
				margin-bottom: 5px;
				padding: 6px 12px;
				height: auto;
				font-size: 12px;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	.lang-switcher {
		margin-left: 100px;

		@media(max-width: 1600px) {
			margin-left: 30px;
		}

		@media(max-width: 991px) {
			margin-left: 15px;
		}

		@media(max-width: 767px) {
			position: absolute;
			right: 15px;
			top: 24px;
		}
	}

	&-sidebarBtn {
		display: none;
		min-width: 48px;
		height: 48px;
        margin-right: 20px;
        background: none;
        border: 0;
        padding: 0;
        position: relative;

		@media(max-width: 1200px) {
			display: block;
		}

		@media(max-width: 767px) {
			height: 36px;
			min-width: 36px;
			margin-right: 15px;
			position: absolute;
			top: 20px;
			left: 15px;
		}

		@media(max-width: 360px) {
			margin-right: 10px;
		}

        span {
            position: absolute;
            background: #fff;
            width: 26px;
            height: 2px;
            border-radius: 2px;
            left: 12px;
            top: 14px;
            transition: 0.3s;

			@media(max-width: 767px) {
				width: 24px;
				left: 6px;
				top: 11px;
			}

            &:nth-child(2) {
                top: 22px;
				opacity: 1;

				@media(max-width: 767px) {
					top: 19px;
				}
            }

            &:nth-child(3) {
                top: 30px;

				@media(max-width: 767px) {
					top: 27px;
				}
            }
        }

        &--active {
            span {
                &:nth-child(1), &:nth-child(2) {
                    transform: rotate(45deg) translateY(-50%);
					top: 50%;
                }

				&:nth-child(2) {
					opacity: 0;
				}

                &:nth-child(3) {
                    transform: rotate(135deg) translateY(-50%);
                    margin-top: -1.5px;
					top: 50%;
                }
            }
        }
	}
}
